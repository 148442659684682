@import '../../node_modules/@blueprintjs/core/lib/scss/variables';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 165px 15px 50px;
  &.no-padding {
    padding: 150px 0 0 0;
  }
  &.sub-title {
    padding: 200px 15px 0;
  }
  .page-title {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 30px 15px;
    font-size: 2rem;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
    z-index: 10;
    h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 28px;
      font-weight: normal;
    }
    h2 {
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 18px;
    }
  }
}

.auth-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-grow: 1;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 480px;
    padding: 25px;
    background: rgba(0, 0, 0, 0.1);
    .auth-logo {
      width: 120px;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    h1 {
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center;
    }
    h2 {
      margin-bottom: 120px;
      text-align: center;
    }
    .auth-form {
      max-width: 480px;
      margin-top: -100px;
      padding-left: 0;
      padding-right: 0;
    }
    .small-links {
      margin-top: 15;
      text-align: center;
    }
  }
}

.simple-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.admin-vertical-tabs {
  .bp3-tab-panel {
    flex: 1;
  }
}
.culture-vertical-tabs {
  .bp3-tab-panel {
    flex: 1;
  }
}
.social-vertical-tabs {
  .bp3-tab-panel {
    flex: 1;
    max-width: 100%;
  }
}
