.main-menu {
  z-index: 10;
  .logo {
    height: 25px;
    width: auto;
    &:hover {
      cursor: pointer;
    }
  }
}
