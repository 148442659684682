@import "variables.scss";
@import "../../node_modules/@blueprintjs/core/lib/scss/variables";
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap");

body {
  //background: $pt-dark-app-background-color; 
  background: #f4f4f4;
  font-family: 'Mukta', sans-serif;
  #root {
    .app {
      display: flex;
      flex-direction: row;
      margin: auto;
      min-height: 100vh;
      & > * {
        flex-grow: 0;
        flex-shrink: 0;
      }
      & > .bp3-fill {
        flex-grow: 1;
        flex-shrink: 1;
      }
      @import "global.scss";
      @import "header.scss";
      @import "page.scss";
      @import "print.scss";
    }
  }
}
