@import url(https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap);
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
body {
  background: #f4f4f4;
  font-family: "Mukta", sans-serif;
}
body #root .app {
  display: flex;
  flex-direction: row;
  margin: auto;
  min-height: 100vh;
  /*
   * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
   */
}
body #root .app > * {
  flex-grow: 0;
  flex-shrink: 0;
}
body #root .app > .bp3-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
body #root .app .pagination {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
body #root .app .pagination input {
  width: 60px;
}
body #root .app .pagination .disabled-button {
  cursor: default;
  color: "#182026";
}
body #root .app .pagination p {
  margin: 0 15px;
}
body #root .app .breadcrumbs-wrap {
  margin-bottom: 15px;
  background: rgb(254, 243, 199);
  padding-left: 10px;
  border-radius: 3px;
}
body #root .app .breadcrumbs-wrap a {
  text-decoration: underline;
}
body #root .app .multi-imgs-wrap {
  margin-bottom: 15px;
}
body #root .app .multi-imgs-wrap .img-wrap {
  position: relative;
}
body #root .app .multi-imgs-wrap .img-wrap .remove {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
}
body #root .app .multi-imgs-wrap .img-wrap .multi-img {
  max-width: 100%;
  height: auto;
}
body #root .app .multi-imgs-wrap .points {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
body #root .app .multi-imgs-wrap .points li {
  display: inline-block;
  margin: 5px;
}
body #root .app .multi-imgs-wrap .points li button {
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 3px solid #ddd;
  background: #ddd;
}
body #root .app .multi-imgs-wrap .points li button.active {
  background: rgba(0, 0, 0, 0.6);
}
body #root .app .icon-propic {
  width: 18px;
  height: 18px;
  border-radius: 9px;
}
body #root .app .form-creator .empty-form {
  padding: 15px;
  text-align: center;
}
body #root .app .form-creator .drop-target {
  padding: 8px;
  border-radius: 3px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.1);
}
body #root .app .form-creator .element-list .bp3-card {
  padding: 8px;
}
body #root .app .form-creator .element-list .bp3-card h5 {
  margin-bottom: 0;
}
body #root .app .location-form-wrapper .form-ele-wrap {
  margin-bottom: 30px;
}
body #root .app .location-form-wrapper .form-wrap-0 label,
body #root .app .location-form-wrapper .form-wrap-1 label,
body #root .app .location-form-wrapper .form-wrap-2 label,
body #root .app .location-form-wrapper .form-wrap-3 label,
body #root .app .location-form-wrapper .form-wrap-5 label {
  display: none;
}
body #root .app .location-form-wrapper .form-wrap-4 label {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 18px;
}
body #root .app .location-form-wrapper h3 {
  margin-bottom: 30px;
}
body #root .app .culture-form-wrapper {
  clear: both;
}
body #root .app form {
  padding: 15px;
  border-radius: 5px;
}
body #root .app form.plain {
  background: transparent;
  padding: 0;
  border-radius: 0;
}
body #root .app form.location-form {
  padding: 0;
  border-radius: 0;
}
body #root .app form .bp3-heading {
  margin-top: 15px;
}
body #root .app form .bp3-heading:first-child {
  margin-top: 0;
}
body #root .app .form-element {
  padding: 10px 15px 0 30px;
  border: 1px solid #333;
  border-radius: 3px;
  margin-top: 8px;
  position: relative;
}
body #root .app .form-element:first-child {
  margin-top: 0;
}
body #root .app .form-element .drag-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
  background: #333;
  cursor: move;
}
body #root .app .form-element .drag-handle .bp3-icon {
  margin-top: 5px;
  margin-left: -1px;
}
body #root .app .form-element .del-button {
  position: absolute;
  top: 0px;
  right: 0px;
}
body #root .app .form-element .linear {
  margin-bottom: 15px;
}
body #root .app .form-element .linear .stack > .bp3-form-group > .bp3-form-content {
  display: flex;
  flex-direction: row;
}
body #root .app .form-element .linear .stack > .bp3-form-group > .bp3-form-content .bp3-control-group > * {
  flex-shrink: auto;
}
body #root .app .form-element .opsi {
  margin-bottom: 15px;
}
body #root .app .form-element .opsi .opsi-item {
  display: flex;
  flex-direction: row;
}
body #root .app .form-element .opsi .opsi-item button {
  margin: 5px 5px 0 0;
}
body #root .app .form-element .opsi .opsi-item button.delete {
  margin: 5px 0 0 5px;
}
body #root .app .form-element .opsi .opsi-item .bp3-input-group {
  width: 100%;
}
body #root .app .form-element .opsi .opsi-item .bp3-input-group input[type=text] {
  margin-top: 5px;
}
body #root .app .form-table {
  border: 1px solid rgb(187, 187, 187);
}
body #root .app .form-table thead tr th {
  text-align: center;
  vertical-align: middle;
  min-width: 150px;
}
body #root .app .form-table thead tr th.col-btn {
  max-width: 60px !important;
  min-width: 60px !important;
}
body #root .app .leaflet-container {
  height: 480px;
  width: 100%;
}
body #root .app .leaflet-container.indonesia-map {
  height: 100%;
}
body #root .app .leaflet-container.indonesia-map .leaflet-control-container .leaflet-top {
  top: 100px;
}
body #root .app .map-only-wrapper .leaflet-container {
  height: 100%;
}
body #root .app .map-only-wrapper .leaflet-container.indonesia-map-only {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
body #root .app .app-rte {
  min-height: 400px;
}
body #root .app .phr-card {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
body #root .app .phr-card .img {
  width: 100px;
  heieght: 100px;
  margin-right: 15px;
}
body #root .app .phr-card .img img {
  width: 100px;
  height: 100px;
  border-radius: 75px;
}
body #root .app .phr-card .phr-delete {
  position: absolute;
  top: 15px;
  right: 15px;
}
body #root .app .main-menu {
  z-index: 10;
}
body #root .app .main-menu .logo {
  height: 25px;
  width: auto;
}
body #root .app .main-menu .logo:hover {
  cursor: pointer;
}
body #root .app .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 165px 15px 50px;
}
body #root .app .container.no-padding {
  padding: 150px 0 0 0;
}
body #root .app .container.sub-title {
  padding: 200px 15px 0;
}
body #root .app .container .page-title {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  padding: 30px 15px;
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
  z-index: 10;
}
body #root .app .container .page-title h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: normal;
}
body #root .app .container .page-title h2 {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 18px;
}
body #root .app .auth-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-grow: 1;
}
body #root .app .auth-container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 480px;
  padding: 25px;
  background: rgba(0, 0, 0, 0.1);
}
body #root .app .auth-container .content .auth-logo {
  width: 120px;
  height: auto;
  display: block;
  margin: 0 auto;
}
body #root .app .auth-container .content h1 {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
body #root .app .auth-container .content h2 {
  margin-bottom: 120px;
  text-align: center;
}
body #root .app .auth-container .content .auth-form {
  max-width: 480px;
  margin-top: -100px;
  padding-left: 0;
  padding-right: 0;
}
body #root .app .auth-container .content .small-links {
  margin-top: 15;
  text-align: center;
}
body #root .app .simple-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
body #root .app .admin-vertical-tabs .bp3-tab-panel {
  flex: 1 1;
}
body #root .app .culture-vertical-tabs .bp3-tab-panel {
  flex: 1 1;
}
body #root .app .social-vertical-tabs .bp3-tab-panel {
  flex: 1 1;
  max-width: 100%;
}
body #root .app .print-wrap {
  width: 100%;
  padding: 50px 20px 20px 20px;
}
@page {
  body #root .app {
    size: A4;
    margin: 0;
  }
}
@media print {
  body #root .app html, body #root .app body {
    width: 210mm;
    height: 297mm;
  }
  body #root .app .print-wrap {
    padding: 0;
  }
  body #root .app .print-wrap .print-banner {
    display: none;
  }
  body #root .app .print-wrap .pagebreak {
    clear: both;
    page-break-after: always;
  }
  body #root .app .print-wrap h1, body #root .app .print-wrap h2, body #root .app .print-wrap h3, body #root .app .print-wrap h4, body #root .app .print-wrap h5 {
    page-break-after: avoid;
  }
  body #root .app .print-wrap table, body #root .app .print-wrap figure, body #root .app .print-wrap .print-block {
    page-break-inside: avoid;
  }
}
