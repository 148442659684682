.print-wrap {
  width: 100%;
  padding: 50px 20px 20px 20px;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .print-wrap {
    padding: 0;
    .print-banner {
      display: none;
    }
    .pagebreak {
      clear: both;
      page-break-after: always;
    }
    h1, h2, h3, h4, h5 {
      page-break-after: avoid;
    }
    table, figure, .print-block {
      page-break-inside: avoid;
    }
  }
}