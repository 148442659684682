.pagination {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  input {
    width: 60px;
  }
  .disabled-button {
    cursor: default;
    color: '#182026';
  }
  p {
    margin: 0 15px;
  }
}

.breadcrumbs-wrap {
  margin-bottom: 15px;
  background: rgb(254, 243, 199);
  padding-left: 10px;
  border-radius: 3px;
  a {
    text-decoration: underline;
  }
}

.multi-imgs-wrap {
  margin-bottom: 15px;
  .img-wrap {
    position: relative;
    .remove {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
    }
    .multi-img {
      max-width: 100%;
      height: auto;
    }
  }
  .points {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    li {
      display: inline-block;
      margin: 5px;
      button {
        border: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 3px solid #ddd;
        background: #ddd;
        &.active {
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.icon-propic {
  width: 18px;
  height: 18px;
  border-radius: 9px;
}

.form-creator {
  .empty-form {
    padding: 15px;
    text-align: center;
  }
  .drop-target {
    padding: 8px;
    border-radius: 3px;
    margin-bottom: 15px;
    background: rgba(255, 255, 255, 0.1);
  }
  .element-list {
    .bp3-card {
      padding: 8px;
      h5 {
        margin-bottom: 0;
      }
    }
  }
}
.location-form-wrapper {
  .form-ele-wrap {
    margin-bottom: 30px;
  }
  .form-wrap-0,
  .form-wrap-1,
  .form-wrap-2,
  .form-wrap-3,
  .form-wrap-5 {
    label {
      display: none;
    }
  }
  .form-wrap-4 {
    label {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 18px;
    }
  }
  h3 {
    margin-bottom: 30px;
  }
}
.culture-form-wrapper {
  clear: both;
}
form {
  &.plain {
    background: transparent;
    padding: 0;
    border-radius: 0;
  }
  &.location-form {
    padding: 0;
    border-radius: 0;
  }
  padding: 15px;
  border-radius: 5px;
  .bp3-heading {
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.form-element {
  padding: 10px 15px 0 30px;
  border: 1px solid #333;
  border-radius: 3px;
  margin-top: 8px;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
  .drag-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px;
    background: #333;
    cursor: move;
    //color: #000;
    .bp3-icon {
      margin-top: 5px;
      margin-left: -1px;
    }
  }
  .del-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .linear {
    margin-bottom: 15px;
    .stack > .bp3-form-group > .bp3-form-content {
      display: flex;
      flex-direction: row;
      .bp3-control-group > * {
        flex-shrink: auto;
      }
    }
  }
  .opsi {
    margin-bottom: 15px;
    .opsi-item {
      display: flex;
      flex-direction: row;
      button {
        margin: 5px 5px 0 0;
        &.delete {
          margin: 5px 0 0 5px;
        }
      }
      .bp3-input-group {
        width: 100%;
        input[type='text'] {
          margin-top: 5px;
        }
      }
    }
  }
}

.form-table {
  border: 1px solid rgba(187, 187, 187, 1);
  thead {
    tr {
      th {
        text-align: center;
        vertical-align: middle;
        min-width: 150px;
        &.col-btn {
          max-width: 60px !important;
          min-width: 60px !important;
        }
      }
    }
  }
}
.leaflet-container {
  &.indonesia-map {
    height: 100%;
    .leaflet-control-container {
      .leaflet-top {
        top: 100px;
      }
    }
  }
  height: 480px;
  width: 100%;
}
.map-only-wrapper {
  .leaflet-container {
    height: 100%;
    &.indonesia-map-only {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
.app-rte {
  min-height: 400px;
}
.phr-card {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  .img {
    width: 100px;
    heieght: 100px;
    margin-right: 15px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 75px;
    }
  }
  .phr-delete {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}